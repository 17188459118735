<template>
  <div v-loading="loading">
    <div class="flex justify-between search-wrap">
      <el-button type="primary" @click="handleAddRound">添加轮询规则</el-button>
    </div>
    <pagination-table
      ref="pageTable"
      :api="findPage"
      :apiParams="{customerId: 'defaultId'}"
      :pageSize="50"
      height="calc(100vh - 312px)"
    >
      <el-table-column label="规则名称" prop="ruleName" min-width="160">
        <template slot-scope="scope">
          <div :title="scope.row.ruleName" class="line-2">{{ scope.row.ruleName }}</div>
        </template>
      </el-table-column>
      <el-table-column label="支付平台" prop="payPlatformName" min-width="160" />
      <el-table-column label="轮询商户号数量" prop="accountNumber" min-width="160" />
      <el-table-column label="创建时间" prop="createTime" min-width="160" />
      <el-table-column label="更新时间" prop="updateTime" min-width="160">
        <template slot-scope="scope">
          {{ scope.row.updateTime || scope.row.createTime }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="right" min-width="120">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEditRound(scope.row)" class="mr-10">
            编辑
          </el-button>
          <el-popconfirm title="确认删除该轮询规则吗？" @confirm="handleDelete(scope.row.id)">
            <el-button type="text" slot="reference" style="color: red">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </pagination-table>

  </div>
</template>
<script>
import PaginationTable from '@/components/PaginationTable.vue';
import payCenterApi from '@/api/paycenter';
export default {
  components: { PaginationTable },
  name: 'Round',
  data() {
    return {
      form: {
        appId: '',
        payPlatform: '',
      },
      loading: false,
      payPlatformOptions: [],
      originalPayPlatformOptions: [],
    };
  },
  created() {
    // 获取支付平台列表
  },
  methods: {
    findPage: payCenterApi.getRuleFindPage,
    handleSearch() {
      this.$refs['pageTable'].onLoad();
    },
    handleEditRound(item) {
      this.$router.push({
        path: `/payment-management/pay-account/edit-round/${item.id}`,
      })
    },

    handleAddRound() {
      this.$router.push(`/payment-management/pay-account/add-round`)
    },

    handleDelete(id) {
      payCenterApi.deleteRule(id).then(res => {
        if(!res.data) { return };
        this.$refs['pageTable'].onLoad();
        this.$message.success('删除成功！')
      })
    }
  },
};
</script>

<style scoped lang="less">
.line-2 {
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
